* {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}

.v25_69 {
  width: 848px;
  height: fit-content;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 40%;
  left: 5%;
}
.v25_70 {
  width: 848px;
  height: fit-content;
  position: absolute;
  top: 62px;
  left: 0px;
  font-family: Apercu Pro;
  font-weight: Light;
  font-size: 22px;
  opacity: 1;
  text-align: left;
}
.v25_71 {
  width: 827px;
  color: rgba(0, 0, 0, 1);
  position: relative;
  top: 0px;
  left: 0px;
  font-family: Apercu Pro;
  font-weight: Regular;
  font-size: 64px;
  opacity: 1;
  text-align: left;
}
.v25_72 {
  width: 40%;
  height: 661px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 1;
  position: absolute;
  top: 246px;
  left: 60%;
  overflow: hidden;
}
