.text-and-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 147px;
  background: var(--core-settings-menu-background);
  border-radius: 4px;
}

.text-and-toggle * {
  color: var(--core-font-color);
}

.toggle {
  width: 34px;
  height: 14px;
  border-radius: 100px;
  background: var(--core-toggle-background);
}

.toggle:before {
  content: ' \25CF';
  font-size: 24px;
  position: relative;
  bottom: 2px;
  left: -2px;
  color: #e6e6eb;
}

.input-element {
  width: 24px;
  position: relative;
  cursor: pointer;
  color: #fff;
  height: 32px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-shadow: 0 0 0 #000;
  border: none;
  z-index: 1;
  background: transparent;
}

.input-element:focus {
  outline: none;
}

.settings-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.text-and-toggle-event-handler-list {
  position: absolute;
  right: 10px;
}

.generic-text-with-svg {
  position: absolute;
}
