#client-management-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0rem 0.5rem;
  margin: 1rem 3rem;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  min-height: 50vh;
  min-width: 60vw;
  height: 100%;
  max-width: 90vw;
}
